.onboard-card{
    margin: 5vw!important;
    border: none!important;
}

.onboard-text{
    margin-bottom: 3rem!important;
}

.onboard-lable{
    margin-bottom: 1rem!important;
}
