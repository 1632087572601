.settings-card-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.settings-card{
    cursor: pointer;
}

.settings-card:hover{
    cursor: pointer;
    background-color: #464d4e;
    color: #ffffff;
}