.settings-data-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

@media screen and (max-width: 480px) {
    .settings-data-header {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .Global-search {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .add-button {
        width: 100px;
        align-self: flex-end;
    }
}